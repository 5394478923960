import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Form,
    Button,
    Typography,
    Table
} from "antd";
import readXlsxFile from "read-excel-file";

export interface SheetDataSet {
    ean: string;
    status: string;
}

const ProductBulkMapping = () => {

    const [form] = Form.useForm();
    const [errorCount, seterrorCount] = useState<any>(0);
    const [products, setProducts] = useState<any[]>([]);
    const headerText = "Bulk Product Status Mapping";
    const navigate = useNavigate();

    const handleComeBack = () => {
        navigate("/admin/products");
    };

    const validateObjectFieldsWithEarlyExit = (obj: SheetDataSet) => {
        for (const [key, value] of Object.entries(obj)) {
            if (value === undefined || value === null || value === '') {
                return false;
            }
        }
        return true;
    };

    const handleUploadBatchFile = async (e: any) => {
        seterrorCount(0);
        setProducts([]);
        const file = e.target.files[0];
        await readXlsxFile(file).then(async (rows) => {
            let filteredData: any = [];
            const encounteredCombinations = new Set();

            rows.forEach((item: any, index: number) => {
                if (index > 0) {
                    const ean = item[0]?.toString().trim();
                    const status = item[1]?.toString().trim();
                    const sheetDataRow: SheetDataSet = {
                        ean: ean,
                        status: status
                    };
                    const combination =
                        ean +
                        status;
                    if (validateObjectFieldsWithEarlyExit(sheetDataRow) &&
                        !encounteredCombinations.has(combination) && sheetDataRow.ean) {
                        filteredData.push(sheetDataRow);
                        encounteredCombinations.add(combination);
                    } else {
                        seterrorCount((prevCount: number) => prevCount + 1);
                    }
                }
            });
            if (filteredData.length > 0) {
                setProducts(filteredData);
            }
        });
    };

    return (
        <>
            <Typography.Title>{headerText}</Typography.Title>
            <Form
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
            >
                <Table
                    rowKey="order"
                    style={{
                        fontSize: "12px",
                    }}
                    pagination={{
                        pageSize: 10,
                    }}
                    dataSource={products}
                    columns={[
                        {
                            title: "EAN",
                            dataIndex: "ean",
                            key: "ean",
                        },
                        {
                            title: "Status",
                            dataIndex: "status",
                            key: "order_status",
                        }
                    ]}
                />
                <div style={{ marginTop: 15 }}>

                    <input
                        type="file"
                        id="input"
                        accept=".xlsx, .xls"
                        onChange={(e) => {
                            handleUploadBatchFile(e);
                            (e.target as any).value = null;
                        }}
                    />
                </div>
                {errorCount > 0 && <div style={{ color: "red", marginTop: 10 }}>
                    {`Due to duplicate or incorrect data, ${errorCount} records cannot be uploaded. `}
                </div>}
                <Button
                    type="primary"
                    style={{ marginTop: 15 }}
                    onClick={handleComeBack}
                >
                    Go back
                </Button>

                <Button type="primary" style={{ marginLeft: 15 }} htmlType="submit">
                    Save
                </Button>
            </Form>
        </>
    );
}

export default ProductBulkMapping