import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    Form,
    Button,
    Typography,
    DatePicker,
    Table,
    Input,
} from "antd";
import readXlsxFile from "read-excel-file";
import useApiPost from "../../../../hooks/useApiPost";
import {
    formatDateToDMY,
    formatDateToYMD,
    handleEndDateToDateOnly,
    handleStartDateToDateOnly,
} from "../../../../utils/dateTime";
import { toast } from "react-toastify";
import { AppContext } from "../../../../App";
import { VodacomSheetDataSet } from "./vodacom-data-types";
import { endDateValidationRule, nameValidationRule, nameValidationRuleSpaceFix, startDateValidationRule } from "../../../../utils/formValidationRules";

export interface initial {
    name: string;
    startDate: string;
    endDate: string;
}
const AddVodacomData = () => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isFileSelected, setisFileSelected] = useState(false);
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [name, setName] = useState<any>('');
    const [errorCount, seterrorCount] = useState<any>(0);
    const { request, setError } = useApiPost();


    const appContext = useContext(AppContext);

    const [newEntries, setNewEntries] = useState<any[]>([]);

    const { projectId } = appContext;
    const headerText = "Import Vodacom Data";
    const navigate = useNavigate();

    const handleComeBack = () => {
        navigate("/admin/import-vodacom-data");
    };

    const handleSave = async (values: initial) => {
        if (!name || !startDate || !endDate) {
            toast.error("Please enter mandatory fields", {
                position: "bottom-center"
            });
            return;
        }
        if (newEntries.length === 0) {
            notifyError("Please choose excel file");
            return;
        }
        seterrorCount(0)
        setIsLoading(true);

        const response = await request("/office/product-import/import", "POST", {
            project_id: projectId,
            name: values.name,
            valid_from: formatDateToYMD(startDate),
            valid_to: formatDateToYMD(endDate),
            product_data: newEntries,
        });
        if (response?.data?.errors) {
            setNewEntries(response?.data?.errors)
        } else {
            if (response?.status === 200) {
                notifySuccess();
                setIsLoading(false);
                navigate(`/admin/import-vodacom-data`);
            } else {
                notifyError(response?.message);
            }
        }
        setIsLoading(false);
    };

    const notifyError = (msg: string) => {
        toast.error(msg, {
            position: "bottom-center"
        });
    };

    const notifySuccess = () => {
        toast.info("File uploaded successfully", {
            position: "bottom-center"
        });
    };

    const validateObjectFieldsWithEarlyExit = (obj: VodacomSheetDataSet) => {
        for (const [key, value] of Object.entries(obj)) {
            if (value === undefined || value === null || value === '') {
                return false;
            }
        }
        return true;
    };

    const handleUploadBatchFile = async (e: any) => {
        seterrorCount(0);
        setNewEntries([]);
        const file = e.target.files[0];
        await readXlsxFile(file).then(async (rows) => {
            let filteredData: any = [];
            const encounteredCombinations = new Set();

            rows.forEach((item: any, index: number) => {
                if (index > 0) {
                    const l_account = item[0]?.toString().trim();
                    const product_code = item[1]?.toString().trim();
                    const quantity_ordered = item[2]?.toString().trim();
                    const order_status = item[3]?.toString().trim();
                    const order_date = item[4]?.toString().trim();
                    const order_number = item[5]?.toString().trim();
                    const sheetDataRow: VodacomSheetDataSet = {
                        l_account: l_account,
                        product_code: product_code,
                        quantity_ordered: quantity_ordered,
                        order_status: order_status,
                        order_date: order_date ? formatDateToYMD(order_date) : null,
                        order_number: order_number,
                    };
                    const combination =
                        l_account +
                        product_code +
                        order_date +
                        order_status +
                        order_number;
                    if (validateObjectFieldsWithEarlyExit(sheetDataRow) &&
                        !encounteredCombinations.has(combination) && !sheetDataRow.order_date.includes('Invalid date')) {
                        filteredData.push(sheetDataRow);
                        encounteredCombinations.add(combination);
                    } else {
                        seterrorCount((prevCount: number) => prevCount + 1);
                    }
                }
            });
            if (filteredData.length > 0) {
                setNewEntries(filteredData);
            }
        })
        setisFileSelected(true)
    };
    const hasErrors = newEntries.some((entry) => entry.error_msg);

    if (isLoading) {
        return <p>Loading...</p>;
    }
    return (
        <>
            <Typography.Title>{headerText}</Typography.Title>
            <Form
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={(values) => handleSave(values)}
            >
                <div>
                    <Form.Item label="Name" name="name" required rules={nameValidationRuleSpaceFix}>
                        <Input onChange={(text) => setName(text)} />
                    </Form.Item>
                    <Form.Item label="Start" name="startDate" required rules={startDateValidationRule}>
                        <DatePicker
                            allowClear={false}
                            format={'DD/MM/YYYY'}
                            onChange={(e) => setStartDate(handleStartDateToDateOnly(e))}
                        />
                    </Form.Item>
                    <Form.Item
                        label="End"
                        name="endDate"
                        required
                        rules={endDateValidationRule}
                    >
                        <DatePicker
                            allowClear={false}
                            format={'DD/MM/YYYY'}
                            onChange={(e) => setEndDate(handleEndDateToDateOnly(e))}
                        />
                    </Form.Item>
                </div>
                <Table
                    rowKey="order"
                    style={{
                        fontSize: "12px",
                    }}
                    pagination={{
                        pageSize: 10,
                    }}
                    dataSource={newEntries}
                    columns={[
                        {
                            title: "L-Account",
                            dataIndex: "l_account",
                            key: "l_account",
                        },
                        {
                            title: "Product Code",
                            dataIndex: "product_code",
                            key: "product_code",
                        },
                        {
                            title: "Qty Ordered",
                            dataIndex: "quantity_ordered",
                            key: "quantity_ordered",
                        },
                        {
                            title: "Order Status",
                            dataIndex: "order_status",
                            key: "order_status",
                        },
                        {
                            title: "Order Date",
                            dataIndex: "order_date",
                            key: "order_date",
                            render: (text) => <div>{text.length > 0 && formatDateToDMY(text)}</div>,
                        },
                        {
                            title: "Order Number",
                            dataIndex: "order_number",
                            key: "order_number",
                        },
                        ...(hasErrors
                            ? [
                                {
                                    title: "Error",
                                    dataIndex: "error_msg",
                                    key: "error_msg",
                                    render: (text: any) => (
                                        <div style={{ color: text ? "red" : "green" }}>
                                            {text || "No errors"}
                                        </div>
                                    ),
                                },
                            ]
                            : []),
                    ]}
                />
                <>
                    <div>
                        <input
                            type="file"
                            id="input"
                            accept=".xlsx, .xls"
                            onChange={(e) => {
                                handleUploadBatchFile(e);
                            }}
                        />
                    </div>
                    {errorCount.length > 0 && <div style={{ color: "red", margin: 10 }}>
                        {`Due to duplicate or incorrect data, ${errorCount} records cannot be uploaded.`}
                    </div>}
                    {isFileSelected && !newEntries.length && <div style={{ color: "red", margin: 10 }}>
                        {`No Records Found.`}
                    </div>}
                    <Button
                        type="primary"
                        style={{ margin: 10 }}
                        onClick={handleComeBack}
                    >
                        Go back
                    </Button>

                    <Button type="primary" style={{ margin: 10 }} htmlType="submit">
                        Save
                    </Button>
                </>
            </Form>
        </>
    );
};

export default AddVodacomData;