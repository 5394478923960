import { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Typography, Switch, Select } from "antd";
import { json, useNavigate, useParams } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";
import { AppContext } from "../../../../App";
import form from "antd/es/form";
import { toast } from "react-toastify";
import { OptionalnameValidationRule } from "../../../../utils/formValidationRules";

interface IChannel {
  id: string;
  name: string;
}

const ChainsForm = () => {
  const { chainId } = useParams<{ chainId: string }>();
  const { projectId } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");

  const [isActive, setIsActive] = useState<boolean>(true);
  const navigate = useNavigate();
  const headerText = chainId ? "Edit chain" : "Add chain";
  const [chains, setChains] = useState<any[]>([]);
  const { request, setError } = useApiPost();
  const [channel, setChannel] = useState<IChannel>();
  const [channelsOptions, setChannelsOptions] = useState<any[]>([]);
  const [newChannelId, setNewChannelId] = useState<string>("");
  const [form] = Form.useForm();
  const [initalValues, setInitialValues] = useState<any>({
    name: "",
    isActive: false,
    KAMName: "",
    LastKAMName: "",
    KAMMailId: "",
  });

  const fetchChannelsOptions = async () => {
    setIsLoading(true);
    const { channelsOptions } = await request(
      `/office/locations/channels/options`,
      "POST",
      {
        projectId,
      }
    );
    setChannelsOptions(channelsOptions);
    setIsLoading(false);
  };

  const fetchchainData = async () => {
    setIsLoading(true);
    if (!chainId) {
      setIsLoading(false);
      return;
    }
    const { chain } = await request(
      `/office/locations/chains/details`,
      "POST",
      {
        id: chainId,
        projectId,
      }
    );
    if (!chain) {
      navigate(`/admin`);
      return;
    }
    setChannel({
      id: chain.channelId,
      name: chain.channel,
    });
    setChannelsOptions(chain?.channelsOptions);
    const { name, isActive, chains = [], kamDetails } = chain;
    setChains(chains);
    setIsActive(isActive);
    setInitialValues({
      name: name,
      isActive: isActive,
      KAMName: kamDetails?.kam_first_name,
      LastKAMName: kamDetails?.kam_last_name,
      KAMMailId: kamDetails?.kam_email,
    });
    setIsLoading(false);
  };
  useEffect(() => {
    form.setFieldsValue(initalValues);
  }, [initalValues]);
  useEffect(() => {
    fetchChannelsOptions();
  }, [projectId]);

  useEffect(() => {
    fetchchainData();
  }, [chainId]);

  const handleComeBack = () => navigate("/admin/chains");

  const handleInsert = async (values: any) => {
    try {
      // const res = await addNewchain(name, isActive);
      let prepareData = {
        name: values?.name?.trim(),
        isActive: isActive,
        projectId,
        channelId: newChannelId || channel?.id,
        kam_first_name: values?.KAMName?.trim(),
        kam_last_name: values?.LastKAMName?.trim(),
        kam_email: values?.KAMMailId?.trim(),
      };
      if (!prepareData?.kam_email) {
        delete prepareData.kam_email;
      }
      if (!prepareData?.kam_last_name) {
        delete prepareData.kam_last_name;
      }
      if (!prepareData?.kam_first_name) {
        delete prepareData.kam_first_name;
      }
      if(!isActive){
        alert("Chain should be Active")
        return;
      }
      const response = await request(
        "/office/locations/chains/insert",
        "POST",
        prepareData
      );

      if (response.error) {
        alert(response.error);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (values: any) => {
    try {
      if (!chainId) return;
      let prepareData = {
        name: values?.name?.trim(),
        isActive: isActive,
        projectId,
        channelId: newChannelId || channel?.id,
        kam_first_name: values?.KAMName?.trim() == "" || values?.KAMName?.trim() == undefined ? null:values?.KAMName?.trim(),
        kam_last_name: values?.LastKAMName?.trim()  == "" || values?.LastKAMName?.trim() == undefined  ? null :values?.LastKAMName?.trim(),
        kam_email: values?.KAMMailId?.trim()   == ""  || values?.KAMMailId?.trim() == undefined  ? null : values?.KAMMailId?.trim(),
        id: chainId,
      };
      console.log(prepareData, values);
      
      // if (!prepareData?.kam_email && !initalValues?.KAMMailId) {
      //   delete prepareData.kam_email;
      // }
      // if (!prepareData?.kam_last_name && !initalValues?.LastKAMName) {
      //   delete prepareData.kam_last_name;
      // }
      // if (!prepareData?.kam_first_name && !initalValues?.KAMName) {
      //   delete prepareData.kam_first_name;
      // }
      // await updatechain(chainId, isActive, name);
      const res = await request(
        "/office/locations/chains/update",
        "POST",
        prepareData
      );
      if (res.error) {
        alert(res.error);
        if (!isActive) {
          setIsActive(true);
        }
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      //handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        onFinish={chainId ? handleUpdate : handleInsert}
        initialValues={initalValues}
      >
        <Form.Item label="Name" name="name" rules={OptionalnameValidationRule}>
          <Input />
        </Form.Item>

        <Form.Item label="Is active" valuePropName="isActive" name="isActive">
          <Switch checked={isActive} onChange={(e) => setIsActive(e)} />
        </Form.Item>

        {channel && (
          <div
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>Connected to channel {channel.name}</div>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/admin/channel/edit/${channel.id}`);
              }}
            >
              View Channel
            </Button>
          </div>
        )}
        <Form.Item label="Change channel" name="channelId">
          <Select
            // style={{ marginTop: "10/px" }}
            loading={isLoading}
            placeholder="Select"
            options={channelsOptions}
            onChange={(_value, e) => {
              setNewChannelId(_value);
            }}
            showSearch
            filterOption={(input, option) =>
              (option?.label?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item label="KAM Name" name="KAMName" rules={OptionalnameValidationRule}>
          <Input />
        </Form.Item>
        <Form.Item label=" KAM Last Name" name="LastKAMName" rules={OptionalnameValidationRule}>
          <Input />
        </Form.Item>

        <Form.Item
          label="KAM Mail Id"
          name="KAMMailId"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ChainsForm;
